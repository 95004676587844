import React from 'react';
import './scss/app.scss';

function App() {
  return (
    <div className="App">
      <img src="/img/logo3.jpg" alt="Judge Karen Lin for Supreme Court 2023"/>
      <p>The campaign has concluded.<br />Thank you for your support.</p>
    </div>
  );
}

export default App;
